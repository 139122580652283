export const ACCESS_TOKEN_KEY = "ITINNOV-DEVPORTAL-IDP-ACCESS-TOKEN";
export const USER_INFO_KEY = "ITINNOV-DEVPORTAL-USER-INFO";

// export const IDP_AUTH_URL = "https://signin.int.1placedessaisons.com/oauth2/authorize";
// export const IDP_TOKEN_URL = "https://signin.int.1placedessaisons.com/oauth2/token";
// export const IDP_CLIENT_ID = "3_3OEmJMf7BoblbnNi55zgnKAwAa";
// export const IDP_CLIENT_CALLBACK_URL = "https://postman.adfs.int.eulerhermes.com";

export const IDP_AUTH_URL = "https://signin.int.1placedessaisons.com/oauth2/authorize";
export const IDP_TOKEN_URL = "https://signin.int.1placedessaisons.com/oauth2/token";
// export const IDP_CLIENT_ID = "vl83pY1CNkkKeFCTEBpNPpaIg2sa";
export const IDP_CLIENT_ID = "SipmxVze7l5Fs3as6h_WSCpa8ewa";
export const IDP_CLIENT_CALLBACK_URL = "https://innov-portal.int.eulerhermes.io/auth/callback";


// client_id: Yk8c1DTYpuVLfbtSRpXiEKsCraEa
// redirect_uri: https://datascience100-accp.mendixcloud.com/link/auth