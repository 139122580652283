import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

import AuthProvider from "./components/AuthProvider";
import PageLayout from "./components/PageLayout";
import RequireAuth from "./components/RequireAuth";
import PublicPage from "./pages/PublicPage";
import LoginPage from "./pages/LoginPage";
import ProtectedPage from "./pages/ProtectedPage";

import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes>
          <Route element={<PageLayout />}>
            <Route path="/" element={<PublicPage />} />
            <Route path="/auth/callback" element={<LoginPage />} />
            <Route path="/login" element={<LoginPage />} />
            <Route
              path="/protected"
              element={
                <RequireAuth>
                  <ProtectedPage />
                </RequireAuth>
              }
            />
          </Route>
        </Routes>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
