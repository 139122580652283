import React, { useEffect } from "react";
import { Link, Outlet, useSearchParams } from "react-router-dom";
import logo from "../../logo.svg";
import {
  IDP_AUTH_URL,
  IDP_CLIENT_ID,
  IDP_CLIENT_CALLBACK_URL,
} from "../../config";
// import { useAuthContext } from "../AuthProvider";
import styles from "./PageLayout.module.css";

const PageLayout = () => {
  const [searchParams] = useSearchParams();
  const auth_url = `${IDP_AUTH_URL}?response_type=code&client_id=${IDP_CLIENT_ID}&scope=openid&redirect_uri=${IDP_CLIENT_CALLBACK_URL}`;

  // const auth = useAuthContext();

  console.log(auth_url)
  const startAuthentication = () => {
    console.log(auth_url);
    window.location.href = auth_url;
  };

  //   const completeAuthentication = () => {
  //     auth.signIn({ name: "Test User" }, "Test-token");
  //   };

  useEffect(() => {
    const grantCode = searchParams.get("code");
    console.log("Grant code for token exchange : ", grantCode);
  }, [searchParams]);

  return (
    <div className={styles.appLayout}>
      <header className={styles.appHeader}>
        <div className={styles.headerWrapper}>
          <div className={styles.headerBrand}>
            <div className={styles.brandWrapper}>
              <a
                className={styles.brandLink}
                href="https://www.allianz.fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  className={styles.brandImage}
                  src="/Allianz.svg"
                  alt="Allianz Trade"
                  role="none"
                />
              </a>
            </div>
            <div className={styles.brandTitle}>
              {" "}
              Allianz
              <br />
              Trade{" "}
            </div>
          </div>
          <div className={styles.headerActions}>
            <button onClick={startAuthentication}>Sign in</button>
          </div>
        </div>

        
      </header>
      <nav>
        <ul>
          <li>
            <Link to="/">Public Page</Link>
          </li>
          <li>
            <Link to="/protected">Protected Page</Link>
          </li>
        </ul>
      </nav>
      <main className="App-main">
        <Outlet />
      </main>
      <img src={logo} className="App-logo" alt="logo" />
      <p>
        Edit <code>src/App.js</code> and save to reload.
      </p>
      <a
        className="App-link"
        href="https://reactjs.org"
        target="_blank"
        rel="noopener noreferrer"
      >
        Learn React
      </a>
    </div>
  );
};

export default PageLayout;




// {auth.isAuthenticated() ? (
//   <p>
//     Welcome {auth.user["name"]}!{" "}
//     <button
//       onClick={() => {
//         auth.signOut();
//       }}
//     >
//       Sign out
//     </button>
//   </p>
// ) : (
//   <p>
//     You are not logged in.{" "}
//     <button onClick={startAuthentication}>Sign in</button>
//   </p>
// )}