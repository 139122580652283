import React from "react";
import { useLocation, Navigate, useParams } from "react-router-dom";
import { useAuthContext } from "../components/AuthProvider";

const LoginPage = () => {

    const auth = useAuthContext();
    const location = useLocation();
    const params = useParams();
    const from = location.state?.from?.pathname || "/protected";

   const sessionCode = params["code"];
   const sessionState = params["code"];

   if (sessionCode && sessionState) {
      console.log("sessionCode => ", sessionCode);
      console.log("sessionState => ", sessionState);
   }

  if (auth.isAuthenticated()) {
    return <Navigate to={from} replace />;
  }

  return <h3>This is the login page</h3>;
};

export default LoginPage;
