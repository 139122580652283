import React from "react";
import { useLocation, Navigate } from "react-router-dom";
import { useAuthContext } from "./AuthProvider";

const RequireAuth = ({ children }) => {
  const auth = useAuthContext();
  const location = useLocation();

  if (!auth.user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}
export default RequireAuth;
