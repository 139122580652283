import React from "react";

const ProtectedPage = () => {
  return (
    <div>
      <h3>Welcome to</h3>
      <h4>The Protected page</h4>
    </div>
  );
};

export default ProtectedPage;
