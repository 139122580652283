import React from "react";

const PublicPage = () => {
  return (
    <div>
      <h3>Hello</h3>
      <h4>You are the Public page</h4>
    </div>
  );
};

export default PublicPage;
